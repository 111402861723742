// Add your custom JS here.

jQuery(function($) {

  //共通
  var headerH = $("#wrapper-navbar").outerHeight(true);//headerの高さを取得

  //----- スクロールの途中から固定ヘッダーにする
  //スクロール途中からヘッダーの高さを変化させるための設定を関数でまとめる
  function FixedAnime() {
    //ヘッダーの高さを取得
    var scroll = $(window).scrollTop();
    if (scroll >= headerH){//ヘッダーの高さを超えたら
          $('#wrapper-navbar').addClass('HeightMin');//#wrapper-navbarについているHeightMinというクラス名を付与
    }else{
          $('#wrapper-navbar').removeClass('HeightMin');//HeightMinというクラス名を除去
    }
  }

  // 画面をスクロールをしたら動かしたい場合の記述
  $(window).scroll(function () {
    FixedAnime();//スクロール途中からヘッダーの高さを変化させる関数を呼ぶ
  });

  // フェードイン
  $(window).scroll(function (){
    $(".fadeIn").each(function(){
      var imgPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight + windowHeight/5){
        $(this).addClass("is-fadein");
      } else {
        $(this).removeClass("is-fadein");
      }
    });
  });

  // フロントバナーのスライダー
  $(function(){
    $('.list-banner01').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: true,
      speed: 1500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        }
      ]
    });
  });

  // 個別ページの画像スライダー
  $(function(){
    $('.list-photos').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      dots: true,
      fade: true,
      speed: 1500,
    });
  });

});
